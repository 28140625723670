import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'

/**
 * Simple interface for starting a logn
 */
export interface StartLoginResponse {

  /**
   * Something something we have to send to BankId
   */
  autoStartToken: string

  /**
   * Something something we have to send to BankId
   */
  orderRef: string
}

export interface CollectResponse {

  /**
   * The order reference
   */
  orderRef: string

  /**
   * The status of the order
   */
  status: string

  /**
   * A hint of what went wrong
   */
  hintCode?: string

  /**
   * The access token, in form of a jwt to use for further access
   */
  accessToken: string

  /**
   * Present if something is broken
   */
  errorCode: string
}

export interface PersonnummerResponse {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  13?: string

  age?: number
}

@Injectable({
  providedIn: 'root'
})
export class BankIdService {

  /**
   * We need a client
   */
  constructor(
    private httpClient: HttpClient
  ) {
  }

  public startLogin(personnummer: string): Observable<StartLoginResponse> {
    const url = `${environment.authServiceUrl}/login/start`
    const data = {
      personnummer,
      domain: environment.domain,
      type: 'auth',
      scope: 'auth'
    }
    return this.httpClient.put<StartLoginResponse>(url, data)
  }

  public collect(type: string, orderRef: string): Observable<CollectResponse> {
    const url = `${environment.authServiceUrl}/login/collect?orderRef=${orderRef}`
    return this.httpClient.get<CollectResponse>(url)
  }

  public cancel(orderRef: string): Observable<CollectResponse> {
    const url = `${environment.authServiceUrl}/login/cancel?orderRef=${orderRef}`
    return this.httpClient.get<CollectResponse>(url)
  }

  /**
   * Checks a personnummer for validity. This calls the
   * common personnummer lambda on service account.
   */
  public validatePersonNummer(id: string): Observable<PersonnummerResponse> {
    const url = `${environment.authServiceUrl}/personnummer`
    return this.httpClient.put<PersonnummerResponse>(url, {id})
  }
}
