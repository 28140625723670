import {Component, OnDestroy, OnInit} from '@angular/core'
import * as XLSX from 'xlsx'
import {DataService, LoanListItem} from '../../services/data.service'
import {Subscription} from 'rxjs'
import {DatePipe} from '@angular/common'

@Component({
  selector: 'spb-xls-export',
  templateUrl: './xls-export.component.html',
  styleUrls: ['./xls-export.component.scss']
})
export class XlsExportComponent implements OnInit, OnDestroy {

  public loanList: LoanListItem[] = []

  private loanList$: Subscription = new Subscription()

  constructor(
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.loanList$ = this.dataService.loans$.subscribe({
      next: (loans: LoanListItem[]) => {
        this.loanList = loans
      }
    })
  }

  public ngOnDestroy(): void {
    this.loanList$.unsubscribe()
  }

  public doExport(): void {
    const datePipe = new DatePipe('fr')
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    const rows: any = [['Kontor', 'Ansvarig', 'Namn', 'Person-/organisationsnummer', 'Lån',
      'Bindingstid', 'Omsättningsdatum',
      'Startdatum', 'Belopp', 'Levande', 'Ränta', 'Amoretering', 'Nästa amortering',
      'Nästa ffd', 'Typ av brev', 'Ränteavvikelse']]
    this.loanList.forEach(l => {
      rows.push([
        l.office,
        l.responsible,
        l.owner,
        l.id,
        l.loanNumber,
        l.frequency,
        datePipe.transform(l.renewalDate, 'yyyy-MM-dd'),
        // datePipe.transform(l.date, 'yyyy-MM-dd'),
        datePipe.transform(l.start, 'yyyy-MM-dd'),
        l.amount,
        l.alive ? 'Nej' : 'Ja',
        l.interest,
        l.repaymentAmount,
        datePipe.transform(l.repaymentDate, 'yyyy-MM-dd'),
        datePipe.transform(l.interestDate, 'yyyy-MM-dd'),
        l.type,
        l.interestDeviation
      ])
    })
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows)
    XLSX.utils.book_append_sheet(wb, ws, 'lån')
    XLSX.writeFile(wb, `lån.xlsx`)
  }
}
