import {Injectable} from '@angular/core'
import {environment} from '../../environments/environment'
import {map} from 'rxjs/operators'
import {ConfigService, SpbUser} from './config.service'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable} from 'rxjs'
import {DatePipe} from '@angular/common'

export interface LoanListItem {
  id: string // PersonNummer || OrganizationNumber || Samordningsnummer
  date: number
  loanNumber: string
  owner: string
  // Loan start date
  start: number
  // Loan end date
  end: number
  amount: number
  renewalDate: number
  selected: boolean
  responsible: string
  office: string
  alive: boolean
  frequency: string
  interest: number
  repaymentAmount: number
  repaymentDate: number
  interestDate: number
  arrangementId: string
  type: string
  protectedIdentity: boolean
  registeredInKerne: boolean
  newInterestDeviation: string
  newLoanBinding: string
  stringDate: string // This is added so that we can search on dates.
  interestDeviation: number
  ownerType: string
}

export interface LoanResponse {

  id: string

  owner: LoanOwner

  coOwners: LoanOwner[]

  loan: LoanAccount

  product: LoanProduct

  timeStamp: number

  printed: LoanPrintStatus[]

  // Is this item a changed item?
  changed: boolean

  adminData: LoanAdminData

}

export interface LoanAdminData {
  registeredInKerne: boolean
  registeredInMp?: boolean // To be removed eventually
  newInterestDeviation: string
  newLoanBinding: string
}

export interface LoanOwner {
  address1: string // "216 42 LIMHAMN"
  address2: string // "216 42 LIMHAMN"
  address3: string // "216 42 LIMHAMN"
  address4: string // "216 42 LIMHAMN"
  address5: string // "216 42 LIMHAMN"
  deceased: boolean //
  name: string // "Daniel Bergdahl"
  office: string // 10477879 - Should be replaced by raal office
  organizationNumber: string //""
  personNummer: string // "19730193592"
  samordningsNummer: string // ""
  street: string //  "Kungsgatan 13"
  responsiblePersonId: string // S-id
  responsiblePersonName: string // Johan Andersson
  email: string[]
  phone: string[] // We account for multiple emails and phone numbers
  protectedIdentity: boolean
}

export interface LoanAccount {
  currentDebt: number // 70000
  end: string // "2021-02-28T00:00:00.000Z"
  start: string // "2016-04-06T00:00:00.000Z"
  renewalDate: string
  loanNumber: string
  interest: number
  repaymentAmount: number
  repaymentDate: number
  interestDate: number
  arrangementId: string
}

/**
 * * {
  "product": {
    "name": "bolån bunden ränta",
    "interestProfile": 157010036,
    "id": 2801012,
    "interest": "1 år bunden l750 debetrta",
    "frequency": "3 mån"
  },
 */
export interface LoanProduct {
  id: number // This is the product we have connected to, debug only
  code: string // Like l760
  name: string
  interest: string // "1 år bunden debtrest"
  interestProfile: number // The interest profile id for debug only
  frequency: string // 3 år, 5 mån
  interestDeviation: number
}

export interface LoanPrintStatus {
  date: number
  orderedBy: string
  type: string // Think Manual/Postnord
}

export interface ConditionsUrl {
  id: string // Either uuidV4 or a combination of loanNumber_customerId.pdf
  loanNumber: string // The sparbanken loan like 5432654322	52 5146 0407
  customerId: string // Like personnummer
  url: string
  downloaded?: boolean
}

export interface PrintResponse {

  /**
   * The id of the request
   */
  id: string

  /**
   * Status, should be 'CREATED', 'MISSING' or 'COMPLETE'
   */
  status: 'CREATED' | 'MISSING' | 'COMPLETE' | 'FAILED'

  /**
   * A timed URL for the main document
   */
  url: string

  /**
   * A list of urls to "Villkorsbilagor"
   */
  conditionsUrls: ConditionsUrl[]
}

export interface PrintRequest {

  /**
   * To get the result of a started print job, send the id,
   * normally a uuidV4 string
   */
  id?: string

  /**
   * To start a print job set create to true explicitly otherwise
   * set it to false
   */
  create: boolean

  /**
   * A list of Ids to print.
   */
  ids: string[]
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  /**
   * Outsider list of loans
   */
  public loans$: BehaviorSubject<LoanListItem[]> = new BehaviorSubject<LoanListItem[]>([])

  public currentLoan$: BehaviorSubject<LoanResponse> = new BehaviorSubject<LoanResponse>({} as any)

  /**
   * All loans in a list for future reference
   *
   * @private
   */
  private loans: LoanResponse[] = []

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private datePipe: DatePipe
  ) {
  }

  /**
   * If we are logged in we fetch the current user and populate
   * the current config with that one.
   */
  public getCurrentUser(): void {
    const url = `${environment.authServiceUrl}/users/self`
    this.httpClient.get<SpbUser>(url)
      .pipe(map((user: SpbUser) => {
        this.configService.setCurrentUser(user)
      })).subscribe()
  }

  /**
   * Fetch all loans, these will be heavily cached
   */
  public updateLoanList(): void {
    const url = `${environment.dataServiceUrl}/loans`
    this.httpClient.get<LoanResponse[]>(url)
      .pipe(map((loans: LoanResponse[]) => {
        this.loans = loans
        this.loans$.next(this.processLoanData(loans))
      })).subscribe()
  }

  /**
   * Fetch the details for one lone
   */
  public getLoanDetails(loanNumber: string): LoanResponse | undefined {
    const loan = this.loans.find(l => l.loan.loanNumber === loanNumber)
    this.currentLoan$.next(loan as LoanResponse)
    return loan
  }

  /**
   * Print one or more documents
   *
   * @param ids - A list of guids to print
   */
  public print(ids: string[]): Observable<PrintResponse> {
    const url = `${environment.dataServiceUrl}/print`
    return this.httpClient.put<PrintResponse>(url, {ids, create: true})
  }

  /**
   * Get the result of the print operation
   *
   * @param id - The id of the request
   */
  public getPrint(id: string): Observable<PrintResponse> {
    const url = `${environment.dataServiceUrl}/print`
    return this.httpClient.put<PrintResponse>(url, {id})
  }

  public delete(ids: string[]): Observable<any> {
    const url = `${environment.dataServiceUrl}/loans`
    return this.httpClient.request<LoanResponse[]>('delete', url, {body: {ids}}).pipe(
      map((loans: LoanResponse[]) => {
        this.loans = loans
        this.loans$.next(this.processLoanData(loans))
      })
    )
  }

  public saveAdminData(loan: LoanResponse): void {
    const url = `${environment.dataServiceUrl}/loans/${loan.id}`
    this.httpClient.put<LoanResponse>(url, loan)
      .pipe()
      .subscribe({
        next: (updatedLoan: LoanResponse) => {
          this.currentLoan$.next(updatedLoan)
        }
      })
  }

  private createFakeDateString(timeStamp: number): string {
    let fullDate = this.datePipe.transform(timeStamp, 'yyyy-MM-dd', 'UTC') as string
    fullDate += fullDate.replace(/-/g, '')
    return fullDate
  }

  private processLoanData(loanResponse: LoanResponse[]): LoanListItem[] {
    return loanResponse.map((lr: LoanResponse): LoanListItem => {
      const selected: boolean = lr.printed !== undefined
      const adminData: LoanAdminData = lr.adminData || {}
      const renewalDate = new Date(lr.loan.renewalDate).getTime()
      const ownerType = ['l760', 'l770', 'l765', 'l771'].indexOf(lr.product.code) !== -1 ? 'B' : 'P'
      lr.adminData = adminData
      return {
        id: lr.owner.personNummer || lr.owner.organizationNumber || lr.owner.samordningsNummer,
        date: lr.timeStamp * 1000,
        loanNumber: lr.loan.loanNumber,
        owner: lr.owner.name,
        start: new Date(lr.loan.start).getTime(),
        end: new Date(lr.loan.end).getTime(),
        renewalDate,
        amount: lr.loan.currentDebt,
        responsible: lr.owner.responsiblePersonName || 'Saknas',
        office: lr.owner.office || 'Saknas',
        alive: lr.owner.deceased,
        frequency: (lr.product.frequency || '').replace('månader', 'må'),
        selected,
        interest: lr.loan.interest,
        repaymentDate: lr.loan.repaymentDate,
        repaymentAmount: lr.loan.repaymentAmount,
        interestDate: lr.loan.interestDate,
        arrangementId: lr.loan.arrangementId,
        type: lr.changed ? 'V' : 'O',
        protectedIdentity: lr.owner.protectedIdentity,
        // The registered in MP part kan be removed when moving from test
        registeredInKerne: adminData.registeredInKerne || !!adminData.registeredInMp,
        newInterestDeviation: adminData.newInterestDeviation,
        newLoanBinding: adminData.newLoanBinding,
        stringDate: this.createFakeDateString(renewalDate),
        interestDeviation: lr.product.interestDeviation,
        ownerType
      }
    })
  }
}
