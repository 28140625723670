/**
 * this is the Angular 7 way of getting access to the window.
 *
 * Admittedly, I do not understand shit of this!
 */
import {FactoryProvider, InjectionToken} from '@angular/core'

/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const LOCAL_STORAGE = new InjectionToken<Storage>('localStorage')

/**
 * The provider
 */
const localStorageProvider: FactoryProvider = {
  provide: LOCAL_STORAGE,
  useFactory: () => localStorage
}

/**
 * The exported provider(s). We only have one. This is "provided"
 * in app.component.
 */
export const LOCAL_STORAGE_PROVIDERS = [
  localStorageProvider
]
