import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

const ACCESS_TOKEN_NAME = 'loan-renewal-at'

/**
 * An SPB user is a sparbanken employee
 */
export interface SpbUser {
  /**
   * The database Id
   */
  itemId: string

  /**
   * Typically s-ID (s209856)
   */
  sId: string

  /**
   * Personnummer
   */
  sub: string

  /**
   * Full name like Daniel Bergdahl
   */
  name: string

  /**
   *
   */
  phone?: string

  /**
   *
   */
  pet?: string

  /**
   * An array of roles, like "admin", "credit"...
   */
  roles: string[]

  /**
   * We can have an office
   */
  office?: string

  /**
   * Emails is good and should be mandatory
   */
  email?: string
}

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface SpbConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null

  /**
   * If we are administrators we can do stuff.
   */
  admin?: boolean

  /**
   * Employees, anyone in the bank. Can view?
   */
  viewer?: boolean

  /**
   * Set by the HttpIncerceptor when a token is ready to be used
   */
  interceptorReady?: boolean

  /**
   * This must always be set and should be true only if we can
   * proceed with application logic.
   */
  ready: boolean

  /**
   * The current user
   */
  user?: SpbUser
}


@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  public configState: BehaviorSubject<SpbConfiguration> =
    new BehaviorSubject<SpbConfiguration>({ready: false})

  private currentConfig: SpbConfiguration = {ready: false}

  constructor(
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.isLoggedIn()
  }

  private static validateTokenTimeStamp(token: string | undefined | null): boolean {
    if (!token) {
      return false
    }
    const payload = JSON.parse(atob(token.split('.')[1]))
    return payload.exp > new Date().getTime()
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): void {
    const token = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME)
    // The set token will throw if not a valid string
    try {
      this.setToken(token as string)
    } catch (e) {
      // Can happen and is quite common.
    }
  }

  /**
   * Set the authentication token
   *
   * @param token - The token as received from the login service
   */
  public setToken(token: string): void {
    const payload = JSON.parse(atob(token.split('.')[1]))
    this.currentConfig = {
      token: ConfigService.validateTokenTimeStamp(token) ? token : this.resetToken(),
      admin: payload.roles.indexOf('renewalToolAdmin') !== -1,
      viewer: payload.roles.indexOf('renewalToolViewer') !== -1,
      ready: false
    }
    this.checkIfReady()
    this.configState.next(this.currentConfig)
    if (this.currentConfig.ready) {
      this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token)
    }
  }

  /**
   * Reset what ever access token we might have had
   */
  public resetToken(): undefined {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.currentConfig = {ready: false}
    this.configState.next(this.currentConfig)
    return undefined
  }

  /**
   * However finds a user can communicate it here
   */
  public setCurrentUser(user: SpbUser): void {
    this.currentConfig.user = user
    this.configState.next(this.currentConfig)
  }

  /**
   * Set the ready state of the configuration
   */
  public checkIfReady(): void {
    this.currentConfig.ready =
      (this.currentConfig.admin === true || this.currentConfig.viewer === true)
  }

  public getAccessToken(): string | undefined | null {
    // this.isLoggedIn()
    return ConfigService.validateTokenTimeStamp(this.currentConfig.token) ? this.currentConfig.token : null
  }
}
